import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import PostPreview from '../PostPreview'
import Form from '../Form'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import styles from './style.module.sass'

export default ({ data, articles, guides, podcasts }) => {
  const { tags, blogSettings, pageSettings } = data

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollToId = id => {
    gsap.to(window, {
      duration: 1,
      scrollTo: document.getElementById(id),
    })
  }

  return (
    <div className={styles.blog}>
      <div className={styles.wrap}>
        {/*<Header blogSettings={blogSettings} tags={tags} blogPath={pageSettings.path} />*/}

        <div className={styles.anchors}>
          <ul className={styles.anchorsList}>
            {tags && tags.map(tag => (
              <li key={tag._key} className={styles.anchorsLink}>
                <Link to={`${pageSettings.path}${blogSettings.tagsLink}/${tag.slug?.current}`}>{tag.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.section}>
          <div id="articles" className={styles.sectionId} />
          <p className={styles.sectionTitle}>Articles</p>
          <div className={styles.sectionTop}>
            <h2 className={styles.sectionH2}>{blogSettings.articlesTitle}</h2>
            <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.articlesLink}`}>
              See All Articles
            </Link>
          </div>
          <div className={cx(styles.sectionContent, styles.sectionContentArticles)}>
            {articles &&
              articles
                .slice(0, 6)
                .map(article => (
                  <PostPreview key={article._id} {...article} pageSettings={pageSettings} blogSettings={blogSettings} />
                ))}
          </div>
        </div>

        <div className={styles.section}>
          <div id="podcasts" className={styles.sectionId} />
          <p className={styles.sectionTitle}>Podcasts</p>
          <div className={styles.sectionTop}>
            <h2 className={styles.sectionH2}>{blogSettings.podcastsTitle}</h2>
            <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.podcastsLink}`}>
              See All Podcasts
            </Link>
          </div>
          <div className={cx(styles.sectionContent, styles.sectionContentPodcasts)}>
            {podcasts &&
              podcasts
                .slice(0, 2)
                .map(podcast => (
                  <PostPreview key={podcast._id} {...podcast} pageSettings={pageSettings} blogSettings={blogSettings} />
                ))}
          </div>
        </div>

        <div className={styles.form}>
          <Form />
        </div>

        <div className={styles.section}>
          <div id="guides" className={styles.sectionId} />
          <p className={styles.sectionTitle}>Guides</p>
          <div className={styles.sectionTop}>
            <h2 className={styles.sectionH2}>{blogSettings.guidesTitle}</h2>
            <Link className={styles.sectionLink} to={`${pageSettings.path}${blogSettings.guidesLink}`}>
              See All Guides
            </Link>
          </div>
          <div className={cx(styles.sectionContent, styles.sectionContentGuides)}>
            {guides &&
              guides
                .slice(0, 3)
                .map(guide => (
                  <PostPreview key={guide._id} {...guide} pageSettings={pageSettings} blogSettings={blogSettings} />
                ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = ({ blogSettings, tags, blogPath }) => {
  const [contentOpen, setContentOpen] = useState(false)

  const handlerClick = () => {
    setContentOpen(!contentOpen)
  }

  return (
    <>
      <div
        className={cx(styles.nav, {
          [styles.navOpen]: contentOpen,
        })}
        onClick={handlerClick}
      >
        <div className={styles.navWrap}>
          <div className={styles.handler}></div>
          <ul className={styles.navList}>
            {blogSettings.header &&
              blogSettings.header.map(column => (
                <>
                  <li className={cx(styles.navItem, styles.navItemTitle)} key={column._key}>
                    {column.title}
                  </li>
                  {column.links &&
                    column.links.map(link => (
                      <li className={styles.navItem} key={link._key}>
                        <Link className={styles.navLink} to={link.href}>
                          {link.title}
                        </Link>
                      </li>
                    ))}
                </>
              ))}
            <li className={cx(styles.navItem, styles.navItemTitle)}>Collections</li>
            {tags &&
              tags.map(tag => (
                <li key={tag._key} className={styles.navItem}>
                  <Link className={styles.navLink} to={`${blogPath}${blogSettings.tagsLink}/${tag.slug?.current}`}>
                    {tag.title}
                  </Link>
                </li>
              ))}
          </ul>
          <div className={styles.navTitle}>Collections</div>
        </div>
      </div>

      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {blogSettings.header &&
            blogSettings.header.map(column => (
              <div className={styles.headerColumn} key={column._key}>
                <p className={styles.headerTitle}>{column.title}</p>
                <ul className={styles.headerList}>
                  {column.links &&
                    column.links.map(link => (
                      <li className={styles.headerLink} key={link._key}>
                        <Link to={link.href}>{link.title}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
        </div>
        <div className={styles.headerRight}>
          <div className={styles.headerColumn}>
            <p className={styles.headerTitle}>Collections</p>
            <ul className={styles.headerList}>
              {tags &&
                tags.map(tag => (
                  <li key={tag._key} className={styles.headerLink}>
                    <Link to={`${blogPath}${blogSettings.tagsLink}/${tag.slug?.current}`}>{tag.title}</Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
